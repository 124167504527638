<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "qiShi",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(图1)',
                        ],
                        img:[
                            "com/init.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到棋士页",
                            "#按图1说明参数:",
                            "1.棋士支持三个城市对弈者下棋,根据角色场景,勾选对弈者,不可以对弈的NPC不要勾选",
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            "#可以把马哨,鹿,娇子,御风放到快捷栏上,移动时候自动使用",
                            '#角色在指定场景,开始任务,场景自动识别',
                        ],
                        img:[
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
